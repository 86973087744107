import { HP_TYPE, UNIT } from '../../../../../../../../server/constants';

const heatingPlantPriceTemplate = (i18n, configSst) => {
  const { heatpumps, heatingPlantCostPrice } = configSst.Data;
  const isAeroHp = heatpumps.list.some((hp) => hp.type === HP_TYPE.AERO);
  const isGeoHp = heatpumps.list.some((hp) => hp.type === HP_TYPE.GEO);
  return {
    rows: [
      {
        label: i18n._(
          'config.equipmentList.heatingPlantPrice.heatingPlant.total'
        ),
        value: heatingPlantCostPrice.heatingPlantTotal,
        labelClassName: 'heating-plant-price-total',
        valueClassName: 'heating-plant-price-total',
        unit: UNIT.EURO_VAT_EXCLUDING
      },
      {
        label: i18n._('config.equipmentList.heatingPlantPrice.hps.Total'),
        value: heatingPlantCostPrice.heatpumps.total,
        unit: UNIT.EURO,
        precision: 0,
        labelClassName: 'heating-plant-price-section-total',
        valueClassName: 'heating-plant-price-section-total'
      },
      {
        label: i18n._('config.equipmentList.heatingPlantPrice.hps.geo'),
        value: heatingPlantCostPrice.heatpumps.geo,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-detail',
        valueClassName: 'heating-plant-price-detail',
        round: false,
        hidden: !isGeoHp
      },
      {
        label: i18n._('config.equipmentList.heatingPlantPrice.hps.aero'),
        value: heatingPlantCostPrice.heatpumps.aero,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-detail',
        valueClassName: 'heating-plant-price-detail',
        round: false,
        hidden: !isAeroHp
      },
      {
        label: i18n._('config.equipmentList.heatingPlantPrice.modular.total'),
        value: heatingPlantCostPrice.modularTotal,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-section-total',
        valueClassName: 'heating-plant-price-section-total',
        round: false
      },
      {
        label: i18n._(
          'config.equipmentList.heatingPlantPrice.modulesEquipment.total'
        ),
        value: heatingPlantCostPrice.modulesEquipment,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-sub-total',
        valueClassName: 'heating-plant-price-sub-total',
        round: false
      },
      {
        label: i18n._(
          'config.equipmentList.heatingPlantPrice.manufacturing.total'
        ),
        value: heatingPlantCostPrice.manufacturing.total,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-sub-total',
        valueClassName: 'heating-plant-price-sub-total',
        round: false
      },
      {
        label: i18n._(
          'config.equipmentList.heatingPlantPrice.structureHydraulic'
        ),
        value: heatingPlantCostPrice.manufacturing.hydraulicAndStructure,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-detail',
        valueClassName: 'heating-plant-price-detail',
        round: false
      },
      {
        label: i18n._(
          'config.equipmentList.heatingPlantPrice.factoryInsulation'
        ),
        value: heatingPlantCostPrice.manufacturing.factoryInsulation,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-detail',
        valueClassName: 'heating-plant-price-detail',
        round: false
      },
      {
        label: i18n._('config.equipmentList.heatingPlantPrice.siteInsulation'),
        value: heatingPlantCostPrice.manufacturing.siteInsulation,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-detail',
        valueClassName: 'heating-plant-price-detail',
        round: false
      },
      {
        label: i18n._('config.equipmentList.heatingPlantPrice.equipmentConfig'),
        value: heatingPlantCostPrice.manufacturing.equipmentConfig,
        unit: UNIT.EURO,
        labelClassName: 'heating-plant-price-detail',
        valueClassName: 'heating-plant-price-detail',
        round: false
      }
    ]
  };
};

export default heatingPlantPriceTemplate;
