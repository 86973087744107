import React, { useContext } from 'react';
import { getSizingParams } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import FormInput from '../../../../../../components/Form/FormInput';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { isNull } from '../../../../../../utils/data.utils';
import { getTotalLevelsPerNeed } from '../../../../../../utils/heatpump.utils';

const HpTotalLevelsInputCellRenderer = ({ data, value, onFormChange }) => {
  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const updateNeedsLevels = (index, newLevel) => {
    const { services, heatpumps } = config.ConfigsSst[0].Data;
    const { list } = heatpumps;
    services.needs.forEach((need) => {
      // on ajuste le maximum de levels pour les usages de chaque PAC
      if (list[index].capacity[need].levels > newLevel) {
        list[index].capacity[need].levels = newLevel;
      }
      // on ajuste le maximum de levels pour le total des usages
      const totalLevelsPerNeed = getTotalLevelsPerNeed(list, need);
      if (heatpumps.levels[need] > totalLevelsPerNeed) {
        heatpumps.levels[need] = totalLevelsPerNeed;
      }
    });
  };

  const handleLevelsChange = (val) => {
    try {
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === data.id);
      list[index].levels = isNull(val) ? list[index].compressors : val;
      // on ajuste le maximum de levels disponibles pour les usages
      updateNeedsLevels(index, list[index].levels);
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const SIZING = getSizingParams(data.compressors);
  const hasNoLevels = data.compressors === 0;
  return (
    <FormInput
      param={SIZING.levels}
      type={INPUT_TYPE.NUMBER}
      onBlur={(newValue) => handleLevelsChange(newValue)}
      disabled={hasNoLevels}
      value={hasNoLevels ? null : value}
    />
  );
  //#endregion
};

export default HpTotalLevelsInputCellRenderer;
