import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { EQUIPMENT_LIST_TYPE } from '../../../../../../../../server/constants';
import Section from '../../../../../../components/Section/Section';
import './EquipmentListSection.css';
import SortBySupplierList from './sortBySupplierList/SortBySupplierList';
import SortByUnitList from './sortByUnitList/SortByUnitList';

const EquipmentListSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [listType, setListType] = useState(EQUIPMENT_LIST_TYPE.UNIT);
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('config.equipmentList.title')} level={2} open>
      <p>{i18n._('config.equipmentList.description.text')}</p>
      <div className='equipmentList-buttons-container'>
        <Button
          className={`equipmentList-button-unit ${
            listType !== EQUIPMENT_LIST_TYPE.UNIT && 'inactive'
          }`}
          onClick={() => setListType(EQUIPMENT_LIST_TYPE.UNIT)}
        >
          {i18n._('config.equipmentList.button.unit')}
        </Button>
        <Button
          className={`equipmentList-button-supplier ${
            listType !== EQUIPMENT_LIST_TYPE.SUPPLIER && 'inactive'
          }`}
          onClick={() => setListType(EQUIPMENT_LIST_TYPE.SUPPLIER)}
        >
          {i18n._('config.equipmentList.button.supplier')}
        </Button>
      </div>
      {listType === EQUIPMENT_LIST_TYPE.UNIT ? (
        <SortByUnitList />
      ) : (
        <SortBySupplierList />
      )}
    </Section>
  );
  //endregion
};
export default EquipmentListSection;
