import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import './IconAlert.css';

const ICON_SIZE = {
  small: '14px',
  medium: '15px',
  large: '19px'
};

const ICON_TYPE = {
  warning: { icon: faTriangleExclamation, color: 'var(--warning-color)' }
};

const IconAlert = ({
  children,
  showIcon,
  iconType = 'warning',
  iconSize = 'medium',
  tooltipId,
  tooltipMessage,
  portalContainer = document.body,
  place = 'bottom',
  reversed = false
}) => {
  //#region [render]
  const iconRender = (
    <div data-tooltip-content={tooltipMessage} data-tooltip-id={tooltipId}>
      <FontAwesomeIcon
        style={{
          height: ICON_SIZE[iconSize],
          color: ICON_TYPE[iconType].color
        }}
        icon={ICON_TYPE[iconType].icon}
      />
      {tooltipMessage &&
        createPortal(
          <Tooltip
            id={tooltipId}
            place={place}
            arrowColor='#222222'
            opacity={1}
            style={{ zIndex: 3 }}
          />,
          portalContainer
        )}
    </div>
  );

  return (
    <div className='icon-alert-wrapper'>
      {!reversed && children}
      {showIcon && iconRender}
      {reversed && children}
    </div>
  );
  //#endregion
};

export default IconAlert;
