import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { getHpLowerModuleParams } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import FormSelect from '../../../../../../components/Form/FormSelect';
import IconAlert from '../../../../../../components/IconAlert/IconAlert';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getSortedNeeds } from '../../../../../../utils/config.utils';
import { isArrNullOrEmpty, isNull } from '../../../../../../utils/data.utils';
import { getHpLowerModuleSettings } from '../../../../../../utils/modules.utils';
import NeedBadge from '../components/NeedBadge';

const LowerModulesSelectSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, modules } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [memos]
  const nominalDiametersList = useMemo(() => {
    const { list } = config.ConfigsSst[0].Data.heatpumps;
    const ndList = {};
    for (const hp of list) {
      // on prend la liste des DN du module
      const availableNds = modules
        .filter((row) => row.fullName === hp?.lowerModule?.fullName)
        .map((row) => row.NominalDiameter)
        .sort((a, b) => a - b);
      // on enlève les valeurs en double
      ndList[hp.position] = [...new Set(availableNds)];
    }

    return ndList;
  }, []);
  //#endregion

  //#region [methods]
  const handleLowerModuleNdChange = async (value, hpId) => {
    try {
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      const selectedModule = modules
        .filter(
          (row) =>
            row.name === list[index].lowerModule.module &&
            row.NominalDiameter === value
        )
        .sort((a, b) => parseFloat(b.Version) - parseFloat(a.Version))[0];

      list[index].lowerModule = getHpLowerModuleSettings(
        list[index],
        selectedModule
      );
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  return (
    <Section
      title={i18n._('config.hps.hydraulic.lowerModules.selection')}
      level={2}
      open
    >
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.hps.position')}</th>
              <th>{i18n._('config.hps.model')}</th>
              <th>{i18n._('config.hps.type')}</th>
              <th>{i18n._('config.hps.needs')}</th>
              <th>{i18n._('config.hps.lowerModule.name')}</th>
              <th>{i18n._('config.hps.lowerModule.nominalDiameter')}</th>
              <th>{i18n._('config.hps.lowerModule.evapPumpMaxHmt')}</th>
              <th>{i18n._('config.hps.lowerModule.evapPumpMaxFLow')}</th>
              <th>{i18n._('config.hps.lowerModule.condPumpMaxHmt')}</th>
              <th>{i18n._('config.hps.lowerModule.condPumpMaxFlow')}</th>
            </tr>
          </thead>
          <tbody>
            {list
              .sort((hp1, hp2) => hp1.position - hp2.position)
              .map((hp, index) => {
                const sortedNeeds = getSortedNeeds(hp.needs);
                const isLowerModuleNotDesigned =
                  isNull(hp.lowerModule?.version) &&
                  isArrNullOrEmpty(hp.lowerModule?.equipment);
                const params = getHpLowerModuleParams(
                  nominalDiametersList[hp.position]
                );
                return (
                  <tr key={'row_ex_' + index}>
                    <td>{hp.position}</td>
                    <td>{hp.model}</td>
                    <td>{i18n._(`heatpump.type.${hp.type}`)}</td>
                    <td>
                      <div className='equipment-table-badges'>
                        {sortedNeeds.map((need) => (
                          <NeedBadge
                            key={'badge_' + index + need}
                            need={need}
                          />
                        ))}
                      </div>
                    </td>
                    {!hp.lowerModule ? (
                      <td colSpan={6} className='empty'>
                        {i18n._('config.hps.lowerModule.notFound')}
                      </td>
                    ) : (
                      <>
                        <td>
                          <IconAlert
                            showIcon={isLowerModuleNotDesigned}
                            iconSize='small'
                            tooltipId='notDesignedModule'
                            tooltipMessage={i18n._(
                              'config.hps.lowerModule.notDesigned',
                              { lowerModule: hp.lowerModule.name }
                            )}
                          >
                            {hp.lowerModule.name}
                          </IconAlert>
                        </td>
                        <td>
                          <FormSelect
                            param={params.nominalDiameter}
                            value={hp.lowerModule.nominalDiameter}
                            onChange={(value) =>
                              handleLowerModuleNdChange(value, hp.id)
                            }
                          />
                        </td>
                        <td>{hp.lowerModule.evapPumpHmt}</td>
                        <td>{hp.lowerModule.evapPumpFlow}</td>
                        <td>{hp.lowerModule.condPumpHmt}</td>
                        <td>{hp.lowerModule.condPumpFlow}</td>
                      </>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default LowerModulesSelectSection;
