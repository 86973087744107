import { useLingui } from '@lingui/react';
import React, { Fragment } from 'react';
import { UNIT } from '../../../../../../../../../../server/constants';
import EllipsisText from '../../../../../../../../components/EllipsisText/EllipsisText';
import {
  getTableValue,
  isArrNullOrEmpty
} from '../../../../../../../../utils/data.utils';

const SubEquipmentRows = ({ rows }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  if (isArrNullOrEmpty(rows)) return;
  return (
    <Fragment>
      <tr className='second-level-row'>
        <th />
        <th>{i18n._('config.equipmentList.details.equipment')}</th>
        <th />
        <th>{i18n._('config.equipmentList.details.total')}</th>
        <th colSpan={6}></th>
      </tr>
      {rows.map((equipment, index) => {
        return (
          <tr key={`${index}_${equipment.name}`} className='second-level-row'>
            <td />
            <td>
              <EllipsisText
                text={equipment.name}
                maxLength={30}
                tooltipId={`${index}_${equipment.name}`}
              />
            </td>
            <td>{equipment.supplier}</td>
            <td>{getTableValue(i18n, equipment.price, UNIT.EURO, 0)}</td>
            <td colSpan={6}></td>
          </tr>
        );
      })}
    </Fragment>
  );
  //endregion
};
export default SubEquipmentRows;
