import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SUBSIDIES as SUBSIDIES_TYPE } from '../../../../../../server/constants';
import { SUBSIDIES } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const SubsidiesBloc = ({ form, onInputChange }) => {
  // #region [lingui]
  const { i18n } = useLingui();
  // #endregion

  // #region [methods]
  const handleSelectChange = (value) => {
    onInputChange('InitSubventionVersion', value);
  };

  const handleInputChange = (key, value) => {
    onInputChange(key, value);
  };
  // #endregion

  // #region [render]
  return (
    <Bloc title={i18n._('cost.subsidies')} level={2}>
      <ParamSelect
        value={form.InitSubventionVersion}
        onChange={handleSelectChange}
        param={SUBSIDIES.InitSubventionVersion}
        label={i18n._('cost.subsidies.InitSubventionVersion')}
      />
      {form.InitSubventionVersion !== SUBSIDIES_TYPE.NO_SUBSIDIES && (
        <>
          <Row>
            <Col>
              <ParamInput
                label={i18n._('cost.subsidies.InitSubventionMaxCapexPercent')}
                value={form.InitSubventionMaxCapexPercent}
                param={SUBSIDIES.InitSubventionMaxCapexPercent}
                onChange={(value) =>
                  handleInputChange(
                    SUBSIDIES.InitSubventionMaxCapexPercent.key,
                    value
                  )
                }
                unit
                bottomText={getDefaultValue(
                  i18n,
                  SUBSIDIES.InitSubventionMaxCapexPercent
                )}
                type={INPUT_TYPE.NUMBER}
                showInfoIcon
                infoIcon='fa-regular fa-circle-question'
                infoTooltip={i18n._(
                  'cost.subsidies.tooltip.InitSubventionMaxCapexPercent'
                )}
              />
            </Col>
            <Col>
              <ParamInput
                label={i18n._('cost.subsidies.InitSubventionAddOn')}
                value={form.InitSubventionAddOn}
                param={SUBSIDIES.InitSubventionAddOn}
                onChange={(value) =>
                  handleInputChange(SUBSIDIES.InitSubventionAddOn.key, value)
                }
                unit
                bottomText={getDefaultValue(
                  i18n,
                  SUBSIDIES.InitSubventionAddOn
                )}
                type={INPUT_TYPE.NUMBER}
                showInfoIcon
                infoIcon='fa-regular fa-circle-question'
                infoTooltip={i18n._(
                  'cost.subsidies.tooltip.InitSubventionAddOn'
                )}
              />
            </Col>
          </Row>
          <h3>{i18n._('cost.subsidies.InitSubventionENRSmall')}</h3>
          <Row>
            <Col>
              <ParamInput
                label={i18n._('cost.subsidies.InitSubventionHeatENRSmall')}
                value={form.InitSubventionHeatENRSmall}
                param={SUBSIDIES.InitSubventionHeatENRSmall}
                onChange={(value) =>
                  handleInputChange(
                    SUBSIDIES.InitSubventionHeatENRSmall.key,
                    value
                  )
                }
                unit
                bottomText={getDefaultValue(
                  i18n,
                  SUBSIDIES.InitSubventionHeatENRSmall
                )}
                type={INPUT_TYPE.NUMBER}
              />
            </Col>
            <Col>
              <ParamInput
                label={i18n._('cost.subsidies.InitSubventionColdENRSmall')}
                value={form.InitSubventionColdENRSmall}
                param={SUBSIDIES.InitSubventionColdENRSmall}
                onChange={(value) =>
                  handleInputChange(
                    SUBSIDIES.InitSubventionColdENRSmall.key,
                    value
                  )
                }
                unit
                bottomText={getDefaultValue(
                  i18n,
                  SUBSIDIES.InitSubventionColdENRSmall
                )}
                type={INPUT_TYPE.NUMBER}
              />
            </Col>
          </Row>
          <h3>{i18n._('cost.subsidies.InitSubventionENRLarge')}</h3>
          <Row>
            <Col>
              <ParamInput
                label={i18n._('cost.subsidies.InitSubventionHeatENRLarge')}
                value={form.InitSubventionHeatENRLarge}
                param={SUBSIDIES.InitSubventionHeatENRLarge}
                onChange={(value) =>
                  handleInputChange(
                    SUBSIDIES.InitSubventionHeatENRLarge.key,
                    value
                  )
                }
                unit
                bottomText={getDefaultValue(
                  i18n,
                  SUBSIDIES.InitSubventionHeatENRLarge
                )}
                type={INPUT_TYPE.NUMBER}
              />
            </Col>
            <Col>
              <ParamInput
                label={i18n._('cost.subsidies.InitSubventionColdENRLarge')}
                value={form.InitSubventionColdENRLarge}
                param={SUBSIDIES.InitSubventionColdENRLarge}
                onChange={(value) =>
                  handleInputChange(
                    SUBSIDIES.InitSubventionColdENRLarge.key,
                    value
                  )
                }
                unit
                bottomText={getDefaultValue(
                  i18n,
                  SUBSIDIES.InitSubventionColdENRLarge
                )}
                type={INPUT_TYPE.NUMBER}
              />
            </Col>
          </Row>
        </>
      )}
    </Bloc>
  );
  // #endregion
};

export default SubsidiesBloc;
