import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { NEED } from '../../../../../../../../../server/constants';
import { DHW } from '../../../../../../../../../server/models/config/buildingDescription/services/settings.model';
import FormInput from '../../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../../components/Form/FormSelect';
import Section from '../../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../../constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import './DhwSection.css';

const DhwSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [refs]
  const nbErrors = useRef(0);
  //#endregion

  //#region [effects]
  useEffect(() => {
    return () => {
      if (nbErrors.current !== 0) removeErrors(nbErrors.current);
    };
  }, []);
  //#endregion

  //#region [methods]
  const handleAddError = () => {
    nbErrors.current++;
    addError();
  };

  const handleRemoveError = () => {
    nbErrors.current--;
    removeErrors(1);
  };

  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.services[NEED.DHW][param.key] = value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { pMax, temperature, temperatureBack, secondaryControl, dhwType } =
    config.ConfigsSst[0].Data.services[NEED.DHW];
  return (
    <Section title={i18n._('need.dhw')} level={2} open>
      <div className='config-form-row'>
        <FormInput
          value={pMax}
          param={DHW.pMax}
          type={INPUT_TYPE.NUMBER}
          onChange={(value) => handleParamChange(DHW.pMax, value)}
          unit
          label={i18n._('config.services.settings.pMax')}
          addError={handleAddError}
          removeError={handleRemoveError}
        />
        <div className='config-form-column'>
          <span className='form-input-label'>
            {i18n._('config.services.settings.tempDistribRegime')}
          </span>
          <div className='settings-distrib-inputs'>
            <FormInput
              value={temperature}
              param={DHW.temperature}
              type={INPUT_TYPE.NUMBER}
              onChange={(value) => handleParamChange(DHW.temperature, value)}
              unit
              className='form-input-small'
              addError={handleAddError}
              removeError={handleRemoveError}
            />
            <FormInput
              value={temperatureBack}
              param={DHW.temperatureBack}
              type={INPUT_TYPE.NUMBER}
              onChange={(value) =>
                handleParamChange(DHW.temperatureBack, value)
              }
              unit
              className='form-input-small'
              addError={handleAddError}
              removeError={handleRemoveError}
            />
          </div>
          <span className='form-input-small-text'>
            {i18n._('config.services.settings.defaultTemperatures', {
              temp: DHW.temperature.default,
              tempBack: DHW.temperatureBack.default
            })}
          </span>
        </div>
        <Form.Check
          type='switch'
          id='dhwSectionSecondaryControl'
          label={i18n._('config.services.settings.secondaryControl')}
          checked={secondaryControl}
          onChange={(evt) =>
            handleParamChange(DHW.secondaryControl, evt.target.checked)
          }
          className='dhw-section-check'
        />
      </div>
      <FormSelect
        label={i18n._('config.services.settings.dhwType')}
        param={DHW.dhwType}
        value={dhwType}
        onChange={(value) => handleParamChange(DHW.dhwType, value)}
        className='dhw-section-type'
      />
    </Section>
  );
  //#endregion
};

export default DhwSection;
