import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { useContext } from 'react';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getTotalLevelsPerNeed } from '../../../../../../utils/heatpump.utils';

const DeleteCellRenderer = ({ data, onFormChange }) => {
  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleDeleteHpClick = () => {
    try {
      const { services, heatpumps } = config.ConfigsSst[0].Data;
      const newList = cloneDeep(heatpumps.list);
      const index = newList.findIndex((hp) => hp.id === data.id);
      const deletedHp = { ...newList[index] };
      newList.splice(index, 1);
      newList.forEach((hp) => {
        if (hp.position > deletedHp.position) hp.position--;
      });
      heatpumps.list = newList;

      // on réajuste le nombre maximum de levels par besoin si nécessaire
      const totalHpLevels = heatpumps.list.reduce((acc, hp) => {
        return acc + hp.levels;
      }, 0);

      services.needs.forEach((need) => {
        if (heatpumps.levels[need] <= totalHpLevels) return;
        heatpumps.levels[need] = getTotalLevelsPerNeed(heatpumps.list, need);
      });

      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <FontAwesomeIcon
      icon='trash-alt'
      className='selected-hps-delete'
      onClick={handleDeleteHpClick}
    />
  );
  //#endregion
};

export default DeleteCellRenderer;
