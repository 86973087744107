import { useLingui } from '@lingui/react';
import React from 'react';
import ResultsExpandableSection from '../../../../../components/ExpandableSection/ResultsExpandableSection.jsx';
import ResultsCards from '../../../../../components/ResultsCards/ResultsCards.jsx';
import Section from '../../../../../components/Section/Section.jsx';
import AccentaVsRefTable from '../../../../../components/TemplateTables/AccentaVsRefTable/AccentaVsRefTable.jsx';
import ResultsTable from '../../../../../components/TemplateTables/ResultsTable/ResultsTable.jsx';
import CapexBySSTTable from './components/CapexBySSTTable.jsx';
import DetailedOpexLinesChart from './components/DetailedOpexLinesChart.jsx';
import DetailedOpexTable from './components/DetailedOpexTable.jsx';
import OpexTable from './components/OpexTable.jsx';
import {
  capexOpexTotalCompareTemplate,
  capexTotalCompareTemplate,
  capexTotalTemplates,
  opexTemplates,
  opexTotalCompareTemplate,
  totalOpexCapexTemplate
} from './templates.js';

const EconomicAnalysisSection = ({ selectedResult, comparedResults }) => {
  // #region [lingui]
  const { i18n } = useLingui();
  // #endregion

  // #region [render]
  const { substations } = selectedResult.ComputeResult.inp;
  if (!substations) return null;
  return (
    <Section title={i18n._('results.economicAnalysis')} open>
      {substations.length > 1 &&
        substations.map((sst, sstIndex) => (
          <CapexBySSTTable
            sstName={sst.InitStationName}
            comparedResults={comparedResults}
            key={'economicAnalysis_capexBySst_' + sstIndex}
          />
        ))}
      <h2>{i18n._('results.economicAnalysis.capexTotal')}</h2>
      {capexTotalTemplates(i18n, comparedResults).map((template, index) => (
        <ResultsTable
          level={3}
          template={template}
          comparedResults={comparedResults}
          key={'economicAnalysis_capexTotal_template_' + index}
        />
      ))}
      <h2>{i18n._('total')}</h2>
      <AccentaVsRefTable
        template={capexTotalCompareTemplate(i18n, comparedResults)}
        comparedResults={comparedResults}
      />
      <h2>{i18n._('results.economicAnalysis.opex')}</h2>
      {opexTemplates(i18n, comparedResults).map((template, index) => (
        <ResultsTable
          level={3}
          template={template}
          comparedResults={comparedResults}
          key={'economicAnalysis_opex_template_' + index}
        />
      ))}
      <h2>{i18n._('total')}</h2>
      <AccentaVsRefTable
        template={opexTotalCompareTemplate(i18n, comparedResults)}
        comparedResults={comparedResults}
      />
      <ResultsExpandableSection comparedResults={comparedResults}>
        <ResultsCards
          element={OpexTable}
          title={i18n._('results.economicAnalysis.opexTable.title')}
          comparedResults={comparedResults}
        />
        <ResultsCards
          element={DetailedOpexTable}
          comparedResults={comparedResults}
          title={i18n._('results.economicAnalysis.detailedOpexTable.title')}
        />
        <ResultsCards
          element={DetailedOpexLinesChart}
          comparedResults={comparedResults}
          title={i18n._(
            'results.economicAnalysis.detailedOpexLinesChart.title'
          )}
        />
      </ResultsExpandableSection>
      <h2>{i18n._('total')}</h2>
      <AccentaVsRefTable
        template={capexOpexTotalCompareTemplate(i18n, comparedResults)}
        comparedResults={comparedResults}
      />
      <ResultsTable
        level={3}
        template={totalOpexCapexTemplate(i18n, comparedResults)}
        comparedResults={comparedResults}
      />
    </Section>
  );
  // #endregion
};

export default React.memo(EconomicAnalysisSection);
