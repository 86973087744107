import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import {
  COLD_MANAGEMENT,
  HP_PE,
  HP_TYPE
} from '../../../../../../../../server/constants';
import { getHpPipingEquipmentParams } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import { hasColdAndHeatOrDhw } from '../../../../../../../../server/utils/heatpump.utils';
import FormSelect from '../../../../../../components/Form/FormSelect';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getSortedNeeds } from '../../../../../../utils/config.utils';
import { isNull } from '../../../../../../utils/data.utils';
import {
  getHpLowerModuleKey,
  getHpLowerModuleSettings
} from '../../../../../../utils/modules.utils';
import NeedBadge from '../components/NeedBadge';

const HpsPipingEquipmentSection = ({ onFormChange }) => {
  // #region [lingui]
  const { i18n } = useLingui();
  // #endregion

  // #region [contexts]
  const { config, modules } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  // #endregion

  // #region [methods]
  const handleColdManagementChange = (value, hpId) => {
    try {
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      const isAeroExchanger =
        HP_TYPE.AERO &&
        (value === COLD_MANAGEMENT.HEAT_SIDE ||
          value === COLD_MANAGEMENT.COLD_SIDE);
      const isV2VBisPresent =
        hasColdAndHeatOrDhw(list[index]) &&
        value === COLD_MANAGEMENT.COLD_WATER_TANK;

      // on change la pompe évap PuE en fonction de l'échangeur
      list[index].pipingEquipment.PuE = isAeroExchanger;
      list[index].pipingEquipment.Hp0_Pr01 = isAeroExchanger;
      list[index].pipingEquipment.Hp0_Pr02 = isAeroExchanger;

      // on change les V2V bis en fonction de la connection au ballon d'eau froide
      list[index].pipingEquipment.VeBis = isV2VBisPresent;
      list[index].pipingEquipment.VcBis = isV2VBisPresent;

      // on change la gestion du froid
      list[index].pipingEquipment.coldManagement = value;

      // on change la présence du collecteur condenseur
      list[index].collectorsEquipment.evapCollector = isAeroExchanger;
      list[index].collectorsEquipment.Vde = isAeroExchanger;

      // on met à jour le nom du module bas avec l'échangeur
      if (config.IsModular) {
        const moduleKey = getHpLowerModuleKey(list[index]);
        const selectedModule = modules
          .filter(
            (row) =>
              row.fullName === moduleKey &&
              row.NominalDiameter === list[index].lowerModule.nominalDiameter
          )
          .sort((a, b) => parseFloat(b.Version) - parseFloat(a.Version))[0];

        list[index].lowerModule = getHpLowerModuleSettings(
          list[index],
          selectedModule
        );
      }
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const checkForEquipmentWarning = (checked, defaultValue) =>
    isNull(defaultValue) ? false : defaultValue !== checked;

  const handleEquipmentChange = (evt, hpId) => {
    try {
      const { checked, value } = evt.target;
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      list[index].pipingEquipment[value] = checked;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  // #endregion

  // #region [render]
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  return (
    <Section
      title={
        config.IsModular
          ? i18n._('config.hps.hydraulic.lowerModules.piping.equipment')
          : i18n._('config.hps.hydraulic.piping.equipment')
      }
      level={2}
      open
    >
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.hps.position')}</th>
              <th>{i18n._('config.hps.model')}</th>
              <th>{i18n._('config.hps.type')}</th>
              <th>{i18n._('config.needs')}</th>
              {Object.values(HP_PE).map((equipment) => (
                <th key={'col_' + equipment}>
                  {i18n._(`hydraulicConfig.table.th.${equipment}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list
              .sort((hp1, hp2) => hp1.position - hp2.position)
              .map((hp, index) => {
                const params = getHpPipingEquipmentParams(hp, config.IsModular);
                const sortedNeeds = getSortedNeeds(hp.needs);
                return (
                  <tr key={'row_pe_' + index}>
                    <td>{hp.position}</td>
                    <td>{hp.model}</td>
                    <td>{i18n._(`heatpump.type.${hp.type}`)}</td>
                    <td>
                      <div className='equipment-table-badges'>
                        {sortedNeeds.map((need) => (
                          <NeedBadge
                            key={'badge_' + index + need}
                            need={need}
                          />
                        ))}
                      </div>
                    </td>
                    {Object.values(params).map((param) => {
                      if (param.hidden) {
                        return (
                          <td className='empty' key={'col_ex_' + param.key} />
                        );
                      }
                      const checked = hp.pipingEquipment?.[param.key];
                      const warning = checkForEquipmentWarning(
                        checked,
                        param.default
                      );
                      const checkboxId = 'hp_pe_' + index + param.key;
                      const selectId = 'hp_pe_' + index + param.key;
                      return param.key === HP_PE.COLD_MANAGEMENT ? (
                        // SELECT GESTION FROID
                        <td
                          key={'col_pe_' + param.key}
                          className='cold-management-select'
                          data-tooltip-content={i18n._(
                            'heatpump.coldManagement.absent'
                          )}
                          data-tooltip-id={'display_' + selectId}
                          style={{ width: 'fit-content' }}
                        >
                          <FormSelect
                            param={param}
                            value={hp.pipingEquipment.coldManagement}
                            onChange={(value) =>
                              handleColdManagementChange(value, hp.id)
                            }
                            disabled={!param.enabled}
                          />
                          {/* toolTip pour afficher le nom complet */}
                          {hp.pipingEquipment.coldManagement ===
                            COLD_MANAGEMENT.ABSENT &&
                            createPortal(
                              <Tooltip
                                id={'display_' + selectId}
                                place='right'
                                className='info-tooltip'
                                opacity={1}
                              />,
                              document.body
                            )}
                        </td>
                      ) : (
                        // CHECKBOX DES EQUIPMENTS
                        <td key={'col_pe_' + param.key}>
                          <div
                            data-tooltip-content={
                              warning
                                ? checked
                                  ? i18n._('equipment.notChecked.warning')
                                  : i18n._('equipment.checked.warning')
                                : null
                            }
                            data-tooltip-id={'error_' + checkboxId}
                            style={{ width: 'fit-content' }}
                          >
                            <Form.Check
                              type='checkbox'
                              label={
                                param.key === HP_PE.COLD_WATER_TANK
                                  ? ''
                                  : i18n._(
                                      `hydraulicConfig.table.td.${param.key}`,
                                      {
                                        position: hp.position
                                      }
                                    )
                              }
                              value={param.key}
                              onChange={(evt) =>
                                handleEquipmentChange(evt, hp.id)
                              }
                              checked={!!checked}
                              className={warning ? 'warning' : ''}
                              disabled={
                                !isNull(param.enabled) && !param.enabled
                              }
                              id={'hps_pe_' + index + '_' + param.key}
                            />
                            {warning &&
                              createPortal(
                                <Tooltip
                                  id={'error_' + checkboxId}
                                  place='bottom'
                                  className='error-tooltip'
                                  arrowColor='#f04460'
                                  opacity={1}
                                />,
                                document.body
                              )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  // #endregion
};

export default HpsPipingEquipmentSection;
