import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  HEADLOSS_WARNINGS,
  HP_CE,
  HP_HE,
  UNIT
} from '../../../../../../../../server/constants';
import {
  fetchExchangerCollectorsHeadLossBody,
  fetchExchangerCollectorsHeadLossData
} from '../../../../../../api/configSst.api';
import FittingsModal from '../../../../../../components/FittingsModal/FittingsModal';
import IconAlert from '../../../../../../components/IconAlert/IconAlert';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import {
  formatValueWithUnit,
  getArraySum,
  isNull,
  isOutOfRange
} from '../../../../../../utils/data.utils';
import { getSortedExchangers } from '../../../../../../utils/heatpump.utils';

const initialFittingsModalState = {
  isOpen: false,
  defaultFittings: undefined,
  initialFittings: undefined,
  exchangerIndex: -1
};

const CollectorsHeadLossSection = ({ onFormChange, onLoading }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast, openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [fittingsModal, setFittingsModal] = useState({
    ...initialFittingsModalState
  });
  //#endregion

  //#region [methods]
  const formatValue = (value, unit, precision) =>
    !isNull(value)
      ? formatValueWithUnit(i18n, value, unit, precision)
      : i18n._('notAvailable');

  const handleFittingsModalClose = () => {
    setFittingsModal(() => ({ ...initialFittingsModalState }));
  };

  const handleFittingsModalValidate = async (fittings) => {
    try {
      onLoading(true);
      const { ConfigSstID, Data } = config.ConfigsSst[0];
      const { exchangersHeadLossData } = Data.pumps;
      const body = {
        exchanger: exchangersHeadLossData[fittingsModal.exchangerIndex],
        fittings,
        isModular: config.IsModular
      };
      exchangersHeadLossData[fittingsModal.exchangerIndex].collectorsHeadLoss =
        await fetchExchangerCollectorsHeadLossData(ConfigSstID, body);
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    } finally {
      onLoading(false);
      handleFittingsModalClose();
    }
  };

  const handleShowHeadLossDataClick = async (exchanger) => {
    try {
      const isModular = config.IsModular;
      const body = { exchanger, isModular };
      const headLossBody = await fetchExchangerCollectorsHeadLossBody(
        config.ConfigsSst[0].ConfigSstID,
        body
      );
      const data = { ...exchanger, headLossBody };
      delete data.hpHeadLoss;
      delete data.pipingHeadLoss;
      delete data.destinationsHeadLoss;

      const filename = `PdC Collecteurs : ${exchanger.exchangerType}${
        exchanger.hpPosition
      } ${exchanger.hpModel} ${i18n._(`need.${exchanger.hpNeed}`)}`;
      openInfoModal(
        filename,
        <div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { heatpumps, pumps } = config.ConfigsSst[0].Data;
  const { exchangersHeadLossData } = pumps;
  const sortedExchangers = getSortedExchangers(exchangersHeadLossData);
  return (
    <Section
      title={
        config.IsModular
          ? i18n._('config.pumps.headLoss.modularCollectorsHeadLoss')
          : i18n._('config.pumps.headLoss.collectorsHeadLoss')
      }
      level={2}
      open
    >
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.pumps.headLoss.pumps')}</th>
              <th>{i18n._('config.pumps.headLoss.hp')}</th>
              <th>{i18n._('config.pumps.headLoss.need')}</th>
              <th>{i18n._('config.pumps.headLoss.connectedTo')}</th>
              <th className='col-flowRate'>
                {i18n._('config.pumps.headLoss.flowRate')}
              </th>
              <th className='col-temperature'>
                {i18n._('config.pumps.headLoss.fluidTemp')}
              </th>
              <th className='col-speed'>
                {i18n._('config.pumps.headLoss.speed')}
              </th>
              <th className='col-headLoss'>
                {i18n._('config.pumps.headLoss.linear')}
              </th>
              <th className='col-fittings'>
                {i18n._('config.pumps.headLoss.fittings')}
              </th>
              <th className='col-headLoss'>
                {i18n._('config.pumps.headLoss.singular')}
              </th>
              <th className='col-headLoss'>
                {i18n._('config.pumps.headLoss.total')}
              </th>
              <th style={{ width: '100px', minWidth: '100px' }}></th>
            </tr>
          </thead>
          <tbody>
            {sortedExchangers.map((exchanger, index) => {
              const isOutOfSpeed = isOutOfRange(
                exchanger.collectorsHeadLoss?.fluidSpeed,
                HEADLOSS_WARNINGS.PUMPS_FLUIDSPEED_RANGE
              );
              const hp = heatpumps.list.find(
                (hp) => hp.position === exchanger.hpPosition
              );
              const collector =
                exchanger.exchangerType === HP_HE.PUE
                  ? HP_CE.EVAP_COLLECTOR
                  : HP_CE.COND_COLLECTOR;
              const absentCollector = !hp.collectorsEquipment[collector];
              const total =
                (exchanger.collectorsHeadLoss?.linearHeadLoss ?? 0) +
                (exchanger.collectorsHeadLoss?.singularHeadLoss ?? 0);
              return (
                <tr key={'exchanger_collectors_headLoss_' + index}>
                  <td>
                    <IconAlert
                      showIcon={absentCollector}
                      tooltipMessage={i18n._(
                        'config.pumps.headLoss.absentCollector'
                      )}
                      tooltipId='absentCollector'
                    >
                      {i18n._(
                        `hydraulicConfig.table.td.${exchanger.exchangerType}`,
                        {
                          position: exchanger.hpPosition
                        }
                      )}
                    </IconAlert>
                  </td>
                  <td>{exchanger.hpModel}</td>
                  <td>{i18n._(`need.${exchanger.hpNeed}`)}</td>
                  <td>
                    {i18n._(
                      `heatpump.operatingPointsSource.${exchanger.hpOperatingPointsSource}`
                    )}
                  </td>
                  <td className='col-flowRate'>
                    {formatValue(
                      exchanger.collectorsHeadLoss
                        ? exchanger.exchangerFlowRate
                        : null,
                      UNIT.CUBIC_METER_PER_HOUR,
                      1
                    )}
                  </td>
                  <td className='col-temperature'>
                    {formatValue(
                      exchanger.collectorsHeadLoss
                        ? exchanger.exchangerFluidTemp
                        : null,
                      UNIT.CELSIUS_DEGREE,
                      2
                    )}
                  </td>
                  <td className={`col-speed ${isOutOfSpeed && 'warning-cell'}`}>
                    {formatValue(
                      exchanger.collectorsHeadLoss?.fluidSpeed,
                      UNIT.METER_PER_SECOND,
                      2
                    )}
                  </td>
                  <td className='col-headLoss'>
                    {formatValue(
                      exchanger.collectorsHeadLoss?.linearHeadLoss,
                      UNIT.WATER_COLUMN_METER,
                      1
                    )}
                  </td>
                  <td className='col-fittings'>
                    {exchanger.collectorsHeadLoss?.fittings ? (
                      <Button
                        onClick={() =>
                          setFittingsModal({
                            isOpen: true,
                            defaultFittings:
                              exchanger.collectorsHeadLoss.defaultFittings,
                            initialFittings:
                              exchanger.collectorsHeadLoss.fittings,
                            exchangerIndex: index
                          })
                        }
                        className='geostorage-edit-fittings-btn'
                      >
                        {i18n._('config.geostorage.fittings.edit', {
                          count: getArraySum(
                            Object.values(exchanger.collectorsHeadLoss.fittings)
                          )
                        })}
                      </Button>
                    ) : (
                      i18n._('notAvailable')
                    )}
                  </td>
                  <td className='col-headLoss'>
                    {formatValue(
                      exchanger.collectorsHeadLoss?.singularHeadLoss,
                      UNIT.WATER_COLUMN_METER,
                      1
                    )}
                  </td>
                  <td className='col-headLoss'>
                    {formatValue(total, UNIT.WATER_COLUMN_METER, 1)}
                  </td>
                  <td style={{ width: '100px', minWidth: '100px' }}>
                    {exchanger.collectorsHeadLoss?.fittings ? (
                      <Button
                        className='pumps-calculations-dl-btn'
                        variant='outline-secondary'
                        onClick={async () =>
                          await handleShowHeadLossDataClick(exchanger)
                        }
                      >
                        <FontAwesomeIcon icon='file-code' />
                        JSON
                      </Button>
                    ) : (
                      i18n._('notAvailable')
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {fittingsModal.isOpen && (
        <FittingsModal
          isOpen={fittingsModal.isOpen}
          defaultFittings={fittingsModal.defaultFittings}
          initialFittings={fittingsModal.initialFittings}
          onClose={handleFittingsModalClose}
          onValidate={handleFittingsModalValidate}
          isModular={config.IsModular}
        />
      )}
    </Section>
  );
  //#endregion
};

export default CollectorsHeadLossSection;
