import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import reactSelectStyles from '../../styles/react-select.style';
import { getGroupHeadingName, getOptionName } from '../../utils/compute.utils';
import './ResultSelect.css';

const ResultSelect = ({
  onResultChange,
  groupedResults,
  selectedResultIndexes,
  placeholder,
  inNewTab
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [memos]
  const groupedOptions = useMemo(() => {
    return groupedResults.map((group, groupIndex) => ({
      label: getGroupHeadingName(i18n, group),
      options: group.results.map((result, resultIndex) => {
        return {
          value: result.ComputeID,
          goalPart: result.GoalPart,
          comment: result.Comment,
          label: getOptionName(i18n, result.GoalPart, result.Comment),
          groupLabel: getGroupHeadingName(i18n, group),
          tags: result.tags,
          indexes: [groupIndex, resultIndex]
        };
      })
    }));
  }, [groupedResults]);
  //#endregion

  //#region [select components]
  const SingleValue = ({ ...props }) => {
    const name = i18n._('result.fullName', {
      group: props.data.groupLabel,
      option: props.data.label
    });
    return (
      <components.SingleValue {...props}>
        <span title={name} className='ellipsis'>
          {name}
        </span>
        <span className='results-select-tags'>
          {props.data.tags.feasibility && (
            <Badge className='feasibility-badge'>
              {i18n._('tag.feasibility')}
            </Badge>
          )}
          {props.data.tags.project && (
            <Badge className='project-badge'>{i18n._('tag.project')}</Badge>
          )}
          {props.data.tags.grantCommitment && (
            <Badge className='grant-commitment-badge'>
              {i18n._('tag.grantCommitment')}
            </Badge>
          )}
          {props.data.tags.contractCommitment && (
            <Badge className='contract-commitment-badge'>
              {i18n._('tag.contractCommitment')}
            </Badge>
          )}
        </span>
      </components.SingleValue>
    );
  };

  const GroupHeading = ({ ...props }) => {
    const { options } = props.data;
    let component;
    let url = `/company/${companyId}/project/${projectId}/design/results/${options[0].value}`;
    if (options.length > 1) {
      const ids = options.slice(1).map((option) => option.value);
      url += `?compare=${ids.join('&compare=')}`;
      component = (
        <>
          <FontAwesomeIcon icon='table' />
          {i18n._('results.select.compare')}
        </>
      );
    } else {
      component = (
        <>
          <FontAwesomeIcon icon='eye' />
          {i18n._('results.select.result')}
        </>
      );
    }
    return (
      <div className='group-header'>
        <components.GroupHeading {...props} />
        <Button
          variant='outline-secondary'
          onClick={() =>
            inNewTab ? window.open(url, '_blank') : navigate(url)
          }
        >
          {component}
        </Button>
      </div>
    );
  };

  const Option = ({ ...props }) => {
    return (
      <components.Option {...props}>
        <div
          className='result-option'
          onClick={async () => await onResultChange(props.data.indexes)}
        >
          <span
            className='result-option-label ellipsis'
            title={props.data.label}
          >
            {props.data.label}
          </span>
          <div className='results-select-tags'>
            {props.data.tags.feasibility && (
              <Badge className='feasibility-badge'>
                {i18n._('tag.feasibility')}
              </Badge>
            )}
            {props.data.tags.project && (
              <Badge className='project-badge'>{i18n._('tag.project')}</Badge>
            )}
            {props.data.tags.contractCommitment && (
              <Badge className='contract-commitment-badge'>
                {i18n._('tag.contractCommitment')}
              </Badge>
            )}
            {props.data.tags.grantCommitment && (
              <Badge className='grant-commitment-badge'>
                {i18n._('tag.grantCommitment')}
              </Badge>
            )}
          </div>
        </div>
      </components.Option>
    );
  };
  //#endregion

  //#region [render]
  return (
    <Select
      value={
        selectedResultIndexes &&
        groupedOptions[selectedResultIndexes[0]].options[
          selectedResultIndexes[1]
        ]
      }
      options={groupedOptions}
      components={{ SingleValue, GroupHeading, Option }}
      styles={reactSelectStyles}
      isSearchable={false}
      className='results-select'
      placeholder={placeholder}
    />
  );
  //#endregion
};

export default ResultSelect;
