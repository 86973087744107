import { NEED } from '../../../server/constants';
import { isArrNullOrEmpty, isNull } from './data.utils';
import {
  checkForHpEmptyNeeds,
  checkForHpMissingCapacitiesError,
  checkForHpsMissingNeedsError,
  isCollectorMissing
} from './heatpump.utils';

export const getSortedNeeds = (needs) => {
  return [NEED.HEAT, NEED.COLD, NEED.DHW, NEED.POOL, NEED.ITES].filter((need) =>
    needs.includes(need)
  );
};

export const isModularCompleted = (configSst) => {
  return !isNull(configSst.Data);
};

export const areBuildingSettingsCompleted = (configSst) => {
  if (!isModularCompleted(configSst)) return false;
  const { needs } = configSst.Data.services;
  return (
    !isArrNullOrEmpty(needs) &&
    needs.every((need) => !isNull(configSst.Data.services[need].pMax))
  );
};

export const isBuildingHydraulicCompleted = (configSst) => {
  if (!areBuildingSettingsCompleted(configSst)) return false;

  // si la config contient des modules, les puissances dimensionnantes doivent être complétées
  if (configSst.Data.services.upperModules) {
    if (
      isNull(configSst.Data.services.upperModules.evaporator.sizingCapacity) ||
      isNull(configSst.Data.services.upperModules.condensor.sizingCapacity)
    )
      return false;
  }
  return true;
};

export const areHpsSettingsCompleted = (configSst) => {
  if (!isBuildingHydraulicCompleted(configSst)) return false;

  const { services, heatpumps } = configSst.Data;
  const { needs } = services;
  if (!heatpumps) return false;

  // au moins un type de PAC doit être sélectionné
  const { settings, list } = heatpumps;
  if (isArrNullOrEmpty(settings.types)) return false;

  // les puissances calorifiques chaudes doivent être bien remplies
  if (
    (needs.includes(NEED.HEAT) || needs.includes(NEED.DHW)) &&
    settings.types.some((type) => isNull(settings[type].pHeat))
  ) {
    return false;
  }

  // les puissances calorifiques froides doivent être bien remplies
  if (
    needs.includes(NEED.COLD) &&
    settings.types.some((type) => isNull(settings[type].pCold))
  ) {
    return false;
  }

  // les capacities de chaque PAC pour chaque usage doivent avoir été récupérées
  if (list.length > 0 && checkForHpMissingCapacitiesError(list, needs))
    return false;

  return true;
};

export const areSelectedHpsCompleted = (configSst) => {
  if (!areHpsSettingsCompleted(configSst)) return false;
  const { services, heatpumps } = configSst.Data;
  if (isArrNullOrEmpty(heatpumps.list)) return false;

  return (
    !checkForHpEmptyNeeds(heatpumps.list) &&
    !checkForHpsMissingNeedsError(heatpumps.list, services.needs)
  );
};

export const areHpsHydraulicCompleted = (configSst) => {
  if (!areSelectedHpsCompleted(configSst)) return false;
  const { heatpumps } = configSst.Data;
  return !isCollectorMissing(heatpumps.list);
};

export const areGeostorageSettingsCompleted = (configSst) => {
  if (
    !areSelectedHpsCompleted(configSst) ||
    !configSst.Data.geostorage?.settings
  )
    return false;
  if (!areHpsHydraulicCompleted(configSst)) return false;
  const { probes, horizontalConnections, collectors } =
    configSst.Data.geostorage.settings;
  return (
    !isNull(probes.count) &&
    !isNull(horizontalConnections.fluidPathDistance) &&
    !isNull(collectors.fluidPathDistance)
  );
};

export const isPageAccessible = (configSst) => ({
  ['general']: true,
  ['services/settings']: isModularCompleted(configSst),
  ['services/hydraulic']: areBuildingSettingsCompleted(configSst),
  ['heatpumps/settings']: isBuildingHydraulicCompleted(configSst),
  ['heatpumps/selection']: areHpsSettingsCompleted(configSst),
  ['heatpumps/hydraulic']: areSelectedHpsCompleted(configSst),
  ['geostorage/settings']: areHpsHydraulicCompleted(configSst),
  ['geostorage/hydraulic']: areGeostorageSettingsCompleted(configSst),
  ['geostorage/calculations']: areGeostorageSettingsCompleted(configSst),
  ['pumps/regimes']: areHpsHydraulicCompleted(configSst),
  ['pumps/heatingPlant']: areHpsHydraulicCompleted(configSst),
  ['pumps/calculations']: areGeostorageSettingsCompleted(configSst),
  ['equipmentList']: areHpsHydraulicCompleted(configSst)
});
