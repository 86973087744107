import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { AgGridReact } from 'ag-grid-react';
import { BroadcastChannel } from 'broadcast-channel';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { fetchConfig, fetchDiagramScope } from '../../../../api/config.api';
import { fetchProjectParent } from '../../../../api/project.api';
import Card from '../../../../components/Card/Card';
import Section from '../../../../components/Section/Section';
import TextFilter from '../../../../components/TextFilter/TextFilter';
import Toast from '../../../../components/Toast/Toast';
import PopupContext from '../../../../contexts/PopupContext';
import SchemaContext from '../../../../contexts/SchemaContext';
import { downloadFile } from '../../../../utils/file.utils';
import './HydraulicDiagramPage.css';
import { SVGMain } from './components/SVGMain';
import { SvcSwitches } from './components/SvcSwitches';
import ShowInfoSwitches from './configComponents/ShowInfoSwitches';

// Modifications à apporter à SvgMain :
// - import SchemaContext from '../../../../../contexts/SchemaContext.jsx'; => pour utiliser le context des checkbox frames et block
// - viewBox: '-700 -100 1045 1600' => pour recentrer le schéma

const HydraulicDiagramPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { configId } = useParams();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [scope, setScope] = useState();
  const [schemaInfos, setSchemaInfos] = useState({});
  const [svcOn, setSvcOn] = useState();
  const [showBlockNames, setShowBlockNames] = useState(true);
  const [showFrames, setShowFrames] = useState(true);
  //#endregion

  //#region [memos]
  const rowData = useMemo(() => {
    if (!scope) return null;
    return Object.entries(scope).map(([variable, value]) => ({
      variable,
      value
    }));
  }, [scope]);

  const columnDefs = useMemo(() => {
    return [
      {
        field: 'variable',
        headerName: i18n._('config.diagram.variable'),
        width: 300,
        filter: TextFilter,
        filterParams: {
          filterPlaceholder: i18n._('config.diagram.variable.filter')
        }
      },
      {
        field: 'value',
        headerName: i18n._('config.diagram.value'),
        sortable: false,
        width: 200,
        cellDataType: 'string'
      }
    ];
  }, []);

  // le scope des variables respilot + les infos à afficher sur le schéma
  const displayedScope = useMemo(() => {
    return { ...scope, ...schemaInfos };
  }, [scope, schemaInfos]);
  //#endregion

  //#region [refs]
  const channel = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        channel.current = new BroadcastChannel(configId);
        const msg = { type: 'connection' };
        channel.current.postMessage(JSON.stringify(msg));
        channel.current.onmessage = async (msg) => {
          try {
            msg = JSON.parse(msg);
            switch (msg.type) {
              case 'scope':
                const newScope = await fetchDiagramScope(configId);
                setScope(() => newScope);
                break;
              default:
                console.warn('unknown broadcast channel type', msg.type);
            }
          } catch (err) {
            throw err;
          }
        };
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();

    return async () => {
      await channel.current?.close();
    };
  }, []);
  //#endregion

  //#region [methods]
  const handleDownloadClick = async () => {
    const entries = Object.entries(scope);
    const csv = entries.reduce((acc, [key, value], index) => {
      if (key.startsWith('schema_')) return acc;
      acc += `${key};${value}${index < entries.length - 1 ? '\n' : ''}`;
      return acc;
    }, '');
    const config = await fetchConfig(configId);
    const project = await fetchProjectParent(config.ProjectID);

    const stringParts = [];

    const projectName = project.otherValues.AhsName;
    stringParts.push(projectName.replace(' ', '-'));
    stringParts.push(config.Name.replace(' ', '-'));
    Object.entries(config.Tags).forEach(
      ([name, value]) =>
        value && stringParts.push(i18n._(`tag.${name}`).replace(' ', '-'))
    );
    stringParts.push(configId);

    const fileName = stringParts.join('_');
    downloadFile(fileName, new Blob([csv], { type: 'text/csv' }));
    toast(() => (
      <Toast
        body={i18n._('config.diagram.variables.downloaded')}
        level='success'
        simpleToast
      />
    ));
  };

  const handleSvcChange = (svc) => {
    setSvcOn({ ...svcOn, svc });
  };

  const handleShowSchemaInfos = (value) => {
    // on ajoute les nouvelles infos à afficher sur le schéma
    setSchemaInfos({ ...schemaInfos, ...value });
  };
  //#endregion

  //#region [render]
  return rowData ? (
    <SchemaContext.Provider
      value={{
        showPipeNames: false,
        showFrames,
        setShowFrames,
        showBlockNames,
        setShowBlockNames
      }}
    >
      <div className='hydraulic-diagram-page'>
        <h1>{i18n._('config.diagram.hydraulicSchema')}</h1>
        <Section level={2} title={i18n._('config.diagram.variables')}>
          <Toaster position='-top-center' reverseOrder={false} />
          <Card>
            <FontAwesomeIcon
              icon='file-csv'
              onClick={handleDownloadClick}
              className='hydraulic-diagram-download-btn'
            />
            <div className='ag-theme-alpine hydraulic-diagram-variables-wrapper'>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                headerHeight={40}
                rowHeight={40}
                defaultColDef={{
                  editable: false,
                  sortable: true,
                  resizable: false
                }}
                suppressCellFocus
                suppressMenuHide
                suppressMovableColumns
                suppressHorizontalScroll
              />
            </div>
          </Card>
        </Section>
        <Section level={2} title={i18n._('config.diagram.schema')} open>
          <div className='hydraulic-diagram-schema-container'>
            {/* Schéma */}
            <Card>
              <SVGMain
                width='100%'
                showAll={false}
                id='schema'
                scope={displayedScope}
                svcOn={{
                  ...svcOn,
                  ServiceSolaireThermique: false,
                  ServiceSolaireSurInjection: false
                }}
              />
            </Card>
            <div className='hydraulic-diagram-switches'>
              {/* Switchs des services */}
              <Card>
                <SvcSwitches
                  scope={displayedScope}
                  svcOn={svcOn}
                  setSvcOn={handleSvcChange}
                  setValue={handleShowSchemaInfos}
                />
              </Card>
              {/* Switchs des infos  */}
              <Card>
                <ShowInfoSwitches />
              </Card>
            </div>
          </div>
        </Section>
      </div>
    </SchemaContext.Provider>
  ) : null;
  //#endregion
};

export default HydraulicDiagramPage;
