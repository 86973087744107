import React, { useContext } from 'react';
import { getSizingParams } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import FormInput from '../../../../../../components/Form/FormInput';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { isNull } from '../../../../../../utils/data.utils';
import { getTotalLevelsPerNeed } from '../../../../../../utils/heatpump.utils';

const HpNeedsLevelsInputCellRenderer = ({
  data,
  value,
  need,
  onFormChange
}) => {
  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const updateNeedTotalLevels = (need) => {
    const { heatpumps } = config.ConfigsSst[0].Data;
    const totalLevelsPerNeed = getTotalLevelsPerNeed(heatpumps.list, need);
    if (heatpumps.levels[need] > totalLevelsPerNeed) {
      heatpumps.levels[need] = totalLevelsPerNeed;
    }
  };

  const handleLevelsChange = (need, val) => {
    try {
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === data.id);
      list[index].capacity[need].levels = isNull(val)
        ? list[index].levels
        : val;
      updateNeedTotalLevels(need);
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  if (!data.needs.includes(need)) return null;
  const SIZING = getSizingParams(data.levels);
  const hasNoLevels = data.compressors === 0;
  return (
    <FormInput
      param={SIZING.levels}
      type={INPUT_TYPE.NUMBER}
      onBlur={(newValue) => handleLevelsChange(need, newValue)}
      disabled={hasNoLevels}
      value={hasNoLevels ? null : value}
    />
  );
  //#endregion
};

export default HpNeedsLevelsInputCellRenderer;
