import React from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';

const EllipsisText = ({ text, maxLength, tooltipId }) => {
  //#region [render]
  if (!text) return <div></div>;
  const isOverflowing = text.length > maxLength;
  return (
    <div data-tooltip-content={text} data-tooltip-id={tooltipId}>
      {isOverflowing ? `${text.slice(0, maxLength)}...` : text}
      {isOverflowing &&
        createPortal(
          <Tooltip
            id={tooltipId}
            place='top'
            arrowColor='#222222'
            opacity={1}
            style={{ zIndex: 3 }}
          />,
          document.body
        )}
    </div>
  );
  //endregion
};

export default EllipsisText;
