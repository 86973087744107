import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { TAG } from '../../../../../../server/constants';
import {
  DESCRIPTION,
  MODULAR
} from '../../../../../../server/models/config/buildingDescription/general.model';
import {
  addConfigTag,
  removeConfigTag,
  replaceConfigTag,
  updateConfig
} from '../../../../api/config.api';
import { fetchConfigIdByTag, fetchResults } from '../../../../api/project.api';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import ResultSelect from '../../../../components/ResultSelect/ResultSelect';
import SaveButton from '../../../../components/SaveButton/SaveButton';
import Tags from '../../../../components/Tags/Tags';
import { FORM_STATUS, INPUT_TYPE } from '../../../../constants';
import ConfigsContext from '../../../../contexts/ConfigsContext';
import PopupContext from '../../../../contexts/PopupContext';
import {
  getGroupHeadingName,
  getOptionName
} from '../../../../utils/compute.utils';
import { isArrNullOrEmpty, isNull } from '../../../../utils/data.utils';
import ConfigFormPage from '../../components/ConfigFormPage/ConfigFormPage';
import './GeneralPage.css';

const GeneralPage = () => {
  // #region [lingui]
  const { i18n } = useLingui();
  // #endregion

  // #region [router]
  const { companyId, projectId } = useParams();
  const location = useLocation();
  // #endregion

  // #region [contexts]
  const { config, setConfig, refreshConfig, refreshConfigTags, modules } =
    useContext(ConfigsContext);
  const { openErrorToast, openConfirmModal } = useContext(PopupContext);
  // #endregion

  // #region [states]
  const [formStatus, setFormStatus] = useState(FORM_STATUS.ORIGIN);
  const [nbErrors, setNbErrors] = useState(0);
  const [designResults, setDesignResults] = useState([]);
  const [selectedDesignResult, setSelectedDesignResult] = useState(undefined);
  // #endregion

  // #region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const results = await fetchResults(projectId);
        setDesignResults([...results]);

        const computeId = location.state?.selectedResultId || config.ComputeID;
        if (!computeId) return;

        const resultIndex = results.findIndex((result) =>
          result.results.some((res) => res.ComputeID === computeId)
        );
        const computeIndex = results
          .at(resultIndex)
          ?.results.findIndex((res) => res.ComputeID === computeId);
        setSelectedDesignResult([resultIndex, computeIndex]);

        if (location.state?.selectedResultId) {
          location.state.selectedResultId = undefined;
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [projectId]);

  useEffect(() => {
    (async () => {
      if (!selectedDesignResult) return;

      const ComputeID =
        designResults[selectedDesignResult[0]].results[selectedDesignResult[1]]
          .ComputeID;

      if (config.ComputeID !== ComputeID) {
        setConfig((conf) => ({
          ...conf,
          ComputeID
        }));
      }

      if (!isSstEnabled) {
        const resultTags =
          designResults[selectedDesignResult[0]].results[
            selectedDesignResult[1]
          ].tags;

        let hasTagsDiff = false;
        for (const tag of Object.values(TAG)) {
          if (resultTags[tag.key] === config.Tags[tag.key]) continue;

          hasTagsDiff = true;
          if (resultTags[tag.key]) {
            switch (tag.key) {
              case TAG.PROJECT.key:
              case TAG.CONTRACT_COMMITMENT.key: {
                const currentTaggedObjectId = await fetchConfigIdByTag(
                  projectId,
                  tag.value
                );
                if (!currentTaggedObjectId) {
                  await addConfigTag(config.ConfigID, tag.value);
                }
                break;
              }
              default:
                await addConfigTag(config.ConfigID, tag.value);
            }
          } else {
            await removeConfigTag(config.ConfigID, tag.value);
          }
        }
        if (hasTagsDiff) {
          await refreshConfigTags();
        }
      }
    })();
  }, [selectedDesignResult]);
  // #endregion

  // #region [methods]
  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);

  const removeErrors = (value) =>
    setNbErrors((prevErrors) => prevErrors - value);

  const save = async () => {
    try {
      await updateConfig(config, modules);
      await refreshConfig();
    } catch (err) {
      throw err;
    }
  };

  const handleParamChange = (param, value) => {
    setConfig((conf) => ({ ...conf, [param.key]: value }));
    setFormStatus(() => FORM_STATUS.DIRTY);
  };

  const handleSaveClick = async () => {
    try {
      setFormStatus(() => FORM_STATUS.SAVING);
      await save();
      setFormStatus(() => FORM_STATUS.SAVED);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setFormStatus(() => FORM_STATUS.DIRTY);
    }
  };

  const handleAddConfigTag = async (configID, tag) => {
    try {
      await addConfigTag(configID, tag.value);
      await refreshConfigTags();
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleRemoveConfigTag = async (configID, tag) => {
    try {
      await removeConfigTag(configID, tag.value);
      await refreshConfigTags();
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleReplaceConfigTag = async (configID, configIDWithTag, tag) => {
    try {
      await replaceConfigTag(configID, configIDWithTag, tag.value);
      await refreshConfigTags();
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  // #endregion

  // #region [render]
  const { Name } = config;

  // Si une sous-station a été initiée avec des données, la config est vérouillée sur "modulaire" ou "non modulaire"
  const isSstEnabled = config.ConfigsSst.some(
    (configSst) => !isNull(configSst.Data)
  );

  return (
    <ConfigFormPage
      formStatus={formStatus}
      formErrors={nbErrors > 0}
      save={save}
    >
      <Bloc title={i18n._('config.general')}>
        <h2>{i18n._('config.general.description')}</h2>

        <div className='config-general-description'>
          <FormInput
            label={i18n._('config.general.Name')}
            value={Name}
            param={DESCRIPTION.Name}
            type={INPUT_TYPE.TEXT}
            onChange={(value) => handleParamChange(DESCRIPTION.Name, value)}
            addError={addError}
            removeError={() => removeErrors(1)}
          />

          <div>
            <div className='form-input-label'>
              {i18n._('config.general.tags')}
            </div>
            <Tags
              currentObjectId={config.ConfigID}
              currentObjectTags={config.Tags}
              getCurrentTaggedObjectId={fetchConfigIdByTag}
              onTagAdd={handleAddConfigTag}
              onTagRemove={handleRemoveConfigTag}
              onTagReplace={handleReplaceConfigTag}
            />
          </div>
        </div>
      </Bloc>
      <Bloc>
        <h2>{i18n._('config.general.resultDesign')}</h2>
        {!isSstEnabled && !isArrNullOrEmpty(designResults) ? (
          <div className='config-general-result-design'>
            <label className='form-input-label'>
              {i18n._('config.general.resultDesign.selectLabel')}
            </label>
            <ResultSelect
              onResultChange={setSelectedDesignResult}
              groupedResults={designResults}
              selectedResultIndexes={selectedDesignResult}
              placeholder={i18n._('config.general.resultDesign.placeholder')}
              inNewTab
            />
          </div>
        ) : (
          <p>
            {selectedDesignResult ? (
              <span>
                {i18n._('config.general.resultDesign.basedOnResult')}
                <Link
                  to={`/company/${companyId}/project/${projectId}/design/results/${
                    designResults[selectedDesignResult[0]].results[
                      selectedDesignResult[1]
                    ].ComputeID
                  }`}
                  target='_blank'
                >
                  {`${getGroupHeadingName(
                    i18n,
                    designResults[selectedDesignResult[0]]
                  )} - ${getOptionName(
                    i18n,
                    designResults[selectedDesignResult[0]].results[
                      selectedDesignResult[1]
                    ].GoalPart,
                    designResults[selectedDesignResult[0]].results[
                      selectedDesignResult[1]
                    ].Comment
                  )}`}
                </Link>
              </span>
            ) : (
              i18n._('config.general.resultDesign.notBasedOnDesignResult')
            )}
          </p>
        )}
      </Bloc>
      <Bloc>
        <h2>{i18n._('config.general.hydraulicDesign')}</h2>
        <Form.Check
          type='switch'
          label={i18n._('config.general.modularDesign')}
          checked={config.IsModular}
          onChange={(evt) =>
            handleParamChange(MODULAR.IsModular, evt.target.checked)
          }
          disabled={isSstEnabled}
        />
      </Bloc>
      <SaveButton
        disabled={nbErrors > 0}
        formStatus={formStatus}
        onClick={
          isSstEnabled
            ? () => handleSaveClick()
            : () =>
                openConfirmModal(
                  i18n._('config.general.modularModal.title'),
                  i18n._('config.general.modularModal.body'),
                  'warning',
                  () => handleSaveClick()
                )
        }
      />
    </ConfigFormPage>
  );
  // #endregion
};

export default GeneralPage;
